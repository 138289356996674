import { UserContextInterface } from '../context';

export type UserType = 'none' | 'client' | 'freemium' | 'member' | 'student';

const isMember = (userCtx: UserContextInterface) =>
  userCtx?.user?.entity?.product_state === 'one_on_one_membership' &&
  userCtx?.user?.students?.some((student) =>
    student?.enablements?.some(
      (enablement) => enablement.product.short_name === 'active_membership'
    )
  );

const isFreemiumByDeactivation = (userCtx: UserContextInterface) => {
  if (!userCtx?.user?.students?.length) return false;
  if (userCtx?.user?.entity?.product_state !== 'one_on_one_membership') return false;

  for (const student of userCtx?.user?.students) {
    if (
      student.enablements?.some(
        (enablement) => enablement.product.short_name === 'active_membership'
      )
    ) {
      return false;
    }
  }

  return true;
};

export { isFreemiumByDeactivation };

const isFreemiumByProductState = (userCtx: UserContextInterface) => {
  if (!userCtx?.user?.students?.length) return false;
  if (userCtx?.user?.entity?.product_state === 'free_class') return true;
  return false;
};

export { isFreemiumByProductState };

const isClient = (userCtx: UserContextInterface) => userCtx?.user?.entity?.is_client;

const isStudent = (userCtx: UserContextInterface) =>
  userCtx?.user?.entity?.role === 'student' && userCtx?.user.students?.length > 0;

export default function getUserType(userContext: UserContextInterface): UserType {
  if (isMember(userContext)) return 'member';
  if (isFreemiumByDeactivation(userContext) || isFreemiumByProductState(userContext))
    return 'freemium';
  if (isStudent(userContext)) return 'student';
  if (isClient(userContext)) return 'client';
  return 'none';
}
