'use client';

import useNspNavigation from '@nrdy-marketing-engine/nsp/src/use-nsp-navigation';
import getUserType, { UserType } from '@nrdy-marketing-engine/server/src/user/@types/get-user';
import { useUserContext } from '@nrdy-marketing-engine/server/src/user/context';
import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

// A little explanation as to what is going on in this file. We show different navbars
// in different circumstances depending on whether the user is logged on, and what kind of
// user they are. (Anonymous, authenticated, student, membership...)
//
// However due to the asynchronous nature of the app, and the fact that a page could be
// server-generated or static, and the fact that not all pages are NSP content, the information
// to calculate the current navigation can come from multiple places and at multiple timings.
//
// So we have a lot of dynamic imports on this page, as well as a loading state of the default
// OrganicNavbar in case the browser needs to fetch the current user information at runtime,
// which typically happens on static pages.

const OrganicNavbar = dynamic(() => import('./navbar/organic-navbar'));
const loading = () => <OrganicNavbar />;
const StudentNavbar = dynamic(() => import('./navbar/student-navbar'), { loading });
const MembershipNavbar = dynamic(() => import('./navbar/membership-navbar'), { loading });
const ClientNavbar = dynamic(() => import('./navbar/client-navbar'), { loading });
const FreemiumNavbar = dynamic(() => import('./navbar/freemium-navbar'), { loading });
const NavbarDetails = dynamic(() => import('./navbar/navbar-details'));

/**
 * The main navigation bar for the site, which automatically switches between desktop
 * and mobile versions depending on the desktop
 */
export default function Navbar({ navigation }: { navigation?: NavigationMenu }) {
  const userCtx = useUserContext();

  const userNavbars: Record<UserType, ComponentType> = {
    client: ClientNavbar,
    freemium: FreemiumNavbar,
    member: MembershipNavbar,
    none: OrganicNavbar,
    student: StudentNavbar,
  };

  // If we have fetched the navigation directly from NSP, we can just render the
  // navigation exactly as we have fetched it.
  const nspNavigation = useNspNavigation() || navigation;
  if (nspNavigation && nspNavigation.slug !== 'student_navbar')
    return <NavbarDetails menu={nspNavigation} />;

  // Otherwise, we need to render the navigation based on the user type.
  const UserNavbar = userNavbars[getUserType(userCtx)];
  return <UserNavbar />;
}
