'use client';

import { useCallback, useEffect } from 'react';

type ObserverCallback = (entries: { contentRect: { height: number } }[]) => void;

function buildResizeObserver(el: HTMLElement, callback: ObserverCallback) {
  if (typeof window.ResizeObserver === 'undefined') {
    const resizeCallback = () => callback([{ contentRect: { height: el.offsetHeight } }]);
    return {
      observe: () => window.addEventListener('resize', resizeCallback, { passive: true }),
      unobserve: () => window.removeEventListener('resize', resizeCallback),
    };
  }
  /* istanbul ignore next */
  return new ResizeObserver(callback);
}

export default function HeaderHeightObserver({ id }: { id?: string }) {
  const calculateHeight = useCallback<ObserverCallback>((entries) => {
    const navbarHeight = entries[0].contentRect.height;
    if (navbarHeight) {
      document.body.style.setProperty('--header-height', `${navbarHeight}px`);
    }
  }, []);

  useEffect(() => {
    const el = id ? document.getElementById(id) : document.getElementsByTagName('header')[0];
    calculateHeight([{ contentRect: { height: el.offsetHeight } }]);
    const resizeObserver = buildResizeObserver(el, calculateHeight);
    resizeObserver.observe(el);
    return () => resizeObserver.unobserve(el);
  }, [id, calculateHeight]);

  return null;
}
