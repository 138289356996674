import { useMemo } from 'react';
import { useNspContext } from './nsp-context';
import transformNspNavigation from './transform-nsp-navigation';

const useNspNavigation = (): NavigationMenu => {
  const { navigation } = useNspContext() || {};
  return useMemo(() => transformNspNavigation(navigation), [navigation]);
};

export default useNspNavigation;
