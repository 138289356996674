import type { CmsResult } from '../fetch-nsp-data';

const buildAccountMenu = (memo: NavigationMenu, menu: NavigationRoot) => ({
  ...memo,
  right: {
    ...memo.right,
    account: menu,
  },
});

const buildBecomeATutorMenu = (memo: NavigationMenu, become_a_tutor: NavigationRoot) => ({
  ...memo,
  become_a_tutor,
});

const buildLogoMenu = (memo: NavigationMenu, logo: NavigationRoot) => ({ ...memo, logo });

const buildNavigationMenu = (memo: NavigationMenu, menu: NavigationRoot) => ({
  ...memo,
  navigation: {
    ...memo.navigation,
    [menu.slug]: menu,
  },
});

export default function transformNspNavigation(nav: CmsResult['navigation']) {
  if (!nav?.menus) return null;

  const initialValue: NavigationMenu = {
    slug: nav.slug,
    navigation: {},
    right: {
      account: null,
      search: nav.showSearch ? { placeholder: 'Find your subject' } : undefined,
      cart: nav.showCart ? { placeholder: 'Cart' } : undefined,
    },
    showCart: nav.showCart,
    showSearch: nav.showSearch,
  };

  return nav.menus.reduce((memo, menu) => {
    if (menu.slug === 'logo') return buildLogoMenu(memo, menu);
    if (menu.slug.startsWith('account')) return buildAccountMenu(memo, menu);
    if (menu.slug.startsWith('become_a_tutor')) return buildBecomeATutorMenu(memo, menu);
    return buildNavigationMenu(memo, menu);
  }, initialValue);
}
